/*
 * SASS Variables to be used across the entire project
 */

// colors
$blue: #224475; // for main content & titles
$dark_blue: #1b365d; // for boxes, some text
$highlight_blue: #00afd7; // buttons & large background txt with 30% transparency
$red: #af272f; // background  colour
$dark_red: #96141c;
$light_grey: #f8f8f8;
$light_grey_alt: #eee;
$grey: #dadada;
$med_grey: #d4d4d4;

// fonts
$font__PFD: 'PFDin', 'Helvetica', 'Arial', sans-serif;
$font__PFDmed: 'PFDin-med', 'Helvetica', 'Arial', sans-serif;
$font__SourceSans: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;
