.modal,
.modal-dialog,
.modal-content {
    min-height: calc(100vh - 6.3rem);
}
.modal {
    z-index: 999999;

    overflow-y: scroll;

    padding-top: 6.3rem;
    .close {
        font-size: 1.8rem;
        font-weight: 500;

        float: none;
        overflow: hidden;

        min-width: 2.3rem;
        min-height: 1.8rem;
        margin-top: 0;
        padding: 1.5rem;

        opacity: 1;
        color: $highlight_blue;
        text-shadow: none;

        @include position(absolute, 0 0 null null);
        &:hover {
            span:first-child {
                color: darken($highlight_blue, 10);
            }
            .close-bar {
                background-color: darken($highlight_blue, 10);
            }
        }
        span {
            transition: all .3s ease-in-out;
            &:first-child {
                font-family: $font__PFDmed;
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 1;

                display: inline-block;

                padding-right: 3.3rem;

                text-transform: uppercase;

                color: $highlight_blue;
            }
        }
        .close-bar {
            display: block;

            width: 2.3rem;
            height: .2rem;

            background-color: $highlight_blue;

            @include position(absolute, 2.3rem 1.3rem null null);
            &:nth-of-type(2) {
                transform: rotate(45deg);
            }
            &:last-of-type {
                transform: rotate(-45deg);
            }
        }
    }
    .nav-tabs {
        overflow-y: scroll;

        width: calc(100% + 3rem);

        white-space: nowrap;

        border: none;
        background-color: white;
        box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, .24);

        @include position(absolute, -8.2rem 0 null -1.5rem);
        li {
            display: inline-block;
            float: none;
            &:last-of-type {
                margin-right: 3rem;
            }
            a {
                font-family: $font__PFDmed;
                font-weight: 500;

                padding: 1.5rem;

                color: $med_grey;
                border: none;
                &:hover {
                    background-color: transparent;
                }
            }
            &.active {
                a {
                    position: relative;

                    color: $highlight_blue;
                    background-color: transparent;
                    &:after {
                        width: 100%;
                        height: .2rem;

                        content: '';

                        background-color: $highlight_blue;

                        @include position(absolute, null 0 .1rem 0);
                    }
                    &:hover {
                        border: none;
                    }
                }
            }
        }
    }
    h2 {
        display: none;
    }
}
.modal-header {
    position: relative;

    display: none;

    height: 5.3rem;
    padding: 0;

    border: none;
}
.modal-dialog {
    width: auto;
    margin: 0;

    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
}
.modal-content {
    padding: 3rem;

    border: none;
    border-radius: 0;
    background-color: $light_grey;
    box-shadow: none;
}
.modal-body {
    padding: 0;
}
.modal-centred .modal-dialog {
    display: inline-block;

    width: 100%;
    max-width: 100%;

    text-align: left;
    vertical-align: middle;
}
.modal-backdrop {
    z-index: 99999;

    background-color: white;
    &.in {
        opacity: .8;
    }
}
.modal:not(#home_details):not(.modal-blue) {
    background-color: white;
}
@media (min-width: 768px) {
    .modal,
    .modal-dialog,
    .modal-content {
        min-height: 100vh;
    }
    .modal {
        padding: 0;
        .close {
            top: 1.5rem;
        }
        .nav-tabs {
            position: relative;
            top: 0;
            left: 0;

            overflow: hidden;

            width: 100%;
            margin-bottom: 4rem;

            box-shadow: none;
            &:after {
                z-index: 0;

                width: 100%;

                content: '';

                border-bottom: .1rem solid $grey;

                @include position(absolute, null 0 .05rem 0);
            }
            li.active {
                a {
                    &:after {
                        z-index: 1;
                    }
                }
            }
        }
        h2 {
            display: block;

            margin: 4rem 0;
        }
    }
    .modal-header {
        display: block;

        height: 7.8rem;

        border-bottom: .2rem solid $grey;
    }
    .modal-content {
        padding-top: 0;

        background-color: white;
    }
}
@media (min-width: 992px) {
    .modal:not(#home_details):not(.modal-blue) {
        .nav-tabs li a:hover {
            color: $highlight_blue;
        }
        h2 {
            margin: 4rem 8.33333333%;
        }
        .modal-dialog {
            background-color: white;
        }
        .modal-content {
            width: 97rem;
            margin: 0 auto;
        }
    }
}
@media (min-width: 1024px) {
    .modal:not(#home_details):not(.modal-blue) {
        .modal-content {
            padding-right: 0;
            padding-left: 0;
        }
    }
}
@media (min-width: 1200px) {
    .modal:not(#home_details):not(.modal-blue) {
        .modal-content {
            width: 117rem;
        }
    }
}

// home_details
.modal#home_details {
    top: 3rem;
    bottom: 3rem;
    left: 50%;

    width: 80%;
    min-height: 0;
    padding: 0;

    transform: translateX(-50%);
    .close {
        top: 1.5rem;
        right: 1.5rem;
        .close-bar {
            top: .8rem;
            right: -.2rem;
        }
    }
    .modal-dialog,
    .modal-content {
        min-height: 0;
    }
    .modal-dialog {
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
    }
    .modal-header {
        display: block;

        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
    }
    .modal-content {
        padding: 0;

        background-color: white;
    }
    .modal-body {
        padding: 0 1.5rem 1.5rem;
    }
    .home-details {
        h3 {
            margin: .5rem 0 1rem;

            text-align: center;
        }
        p:last-of-type {
            margin-bottom: 0;
        }
        strong,
        span {
            display: block;
        }
        strong {
            margin-bottom: .5rem;
        }
    }
    .map-wrapper {
        margin-top: 1rem;
    }
    .location-map {
        height: 18rem;
    }
    @media (min-width: 425px) {
        .home-details {
            padding: 0 2.5rem;
            h3 {
                margin: 1.5rem 0 4rem;
            }
            strong {
                display: inline-block;

                width: 40%;
                margin-bottom: 0;
            }
            span {
                margin-left: 40%;
            }
        }
        .map-wrapper {
            margin-top: 3rem;
        }
        .location-map {
            height: 29rem;
        }
    }
    @media (min-width: 768px) {
        text-align: center;
        &:before {
            display: inline-block;

            height: 100%;

            content: '';
            vertical-align: middle;
        }
        .close {
            right: 1.5rem;
            .close-bar {
                top: 2.3rem;
                right: 1.3rem;
            }
        }
        .modal-dialog {
            width: 60rem;
            max-width: 100%;
        }
        .modal-header {
            height: 6.3rem;

            border-bottom: 0;
        }
        .modal-body {
            padding: 0 3rem 3rem;
        }
        .home-details {
            strong {
                width: 25%;
            }
            span {
                margin-left: 25%;
            }
        }
    }
}

// design_decisions
.modal#design_decisions {
    .modal-content {
        padding-top: 8.2rem;
    }
    .table-heading {
        margin-bottom: 2rem;
        padding-bottom: 1rem;

        border-bottom: .2rem solid $grey;
        p {
            font-family: $font__PFDmed;

            margin: 0;

            color: $highlight_blue;
        }
    }
    .vendor-information {
        margin-bottom: 1rem;
        p {
            margin: 0;
        }
        .vendor-name {
            margin-bottom: 1rem;
        }
        .vendor-contact {
            margin-top: 1rem;
        }
    }
    .design-option {
        padding: 1rem 0;

        border-bottom: .1rem solid $grey;
        &:last-of-type {
            margin-bottom: 2rem;

            border-width: .2rem;
        }
        p {
            margin: 0;
        }
    }
    .group:last-of-type .design-options .design-option:last-of-type {
        margin-bottom: 0;
    }
    @media (min-width: 768px) {
        .modal-content {
            padding-top: 0;
        }
        .table-heading {
            border-width: .1rem;
        }
        .design-option {
            &:first-of-type {
                padding-top: 0;
            }
            &:last-of-type {
                margin-bottom: 0;

                border-bottom: none;
            }
        }
        .group {
            margin-bottom: 3rem;
            padding-bottom: 2rem;

            border-bottom: .1rem solid $grey;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

// important_files
.modal#important_files {
    .modal-content {
        padding-top: 8.2rem;
    }
    .modal-body > .row > div[class^='col-']:after {
        width: 4rem;
        height: 5.2rem;

        content: '';

        background: linear-gradient(to right, rgba(255, 255, 255, 0), white);

        @include position(absolute, -8.2rem -1.5rem null null);
    }
    h3 {
        font-size: 2.0rem;
        line-height: 2.0rem;
        margin: 0 0 .5rem;
    }
    .files {
        > .row > div:nth-last-of-type(n+2) .file {
            margin-bottom: 3rem;
            padding-bottom: 3rem;

            border-bottom: .1rem solid white;
        }
    }
    .file,.video-description {
        a {
            font-size: 1.4rem;
            line-height: 2.3rem;

            position: relative;

            padding-left: 1.8rem;

            color: $highlight_blue;
            &:hover {
                color: darken($highlight_blue, 10);
            }
            i {
                @include position(absolute, 0 null null 0);
                &:before {
                    width: 1.2rem;

                    @include position(absolute, .2rem null null 0);
                }
            }
        }
    }

    .video {
      a {
        img {
          margin-bottom: 1rem;
        }

        .youtube-button {
          background-image: url('../assets/images/youtube_play.png');
          width: 100px;
          height: 100px;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);

          &:hover {
            background-image: url('../assets/images/youtube_play_hover.png');
          }
        }
      }
    }

    @media (min-width: 768px) {
        .modal-content {
            padding-top: 0;
        }
        .modal-body > .row > div[class^='col-']:after {
            display: none;
        }
        h2 {
            margin-bottom: 2.5rem;
        }
        .files {
            > .row > div:nth-last-of-type(n+2) .file {
                border-color: $grey;
            }
        }
    }
    @media (min-width: 992px) {
        .files {
            > .row > div:nth-last-of-type(n+2) .file {
                margin-bottom: 4rem;
                padding: 0;

                border: none;
            }
        }
    }
}

// appointments
.modal#appointments {
    &.has-tabs .modal-content {
        padding-top: 8.2rem;
    }
    h3 {
        margin-top: 0;
    }
    .appointment {
        &:nth-last-of-type(n+2) {
            margin-bottom: 3rem;
            padding-bottom: 3rem;

            border-bottom: .1rem solid white;
        }
        .date-time,
        a {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2.3rem;

            color: $highlight_blue;
        }
        a {
            position: relative;

            padding-left: 1.8rem;
            &:hover {
                color: darken($highlight_blue, 10);
            }
            i {
                color: $dark_blue;

                @include position(absolute, 0 null null 0);
                &:before {
                    width: 1.2rem;

                    @include position(absolute, .2rem null null 0);
                }
            }
        }
    }
    @media (min-width: 768px) {
        &.has-tabs .modal-content {
            padding-top: 0;
        }
        .appointment {
            max-width: 60%;
            &:nth-last-of-type(n+2) {
                margin-bottom: 4rem;
                padding: 0;

                border: none;
            }
        }
    }
}

// service_issues
.modal#service_completed {
    .back-link {
        margin: -1.5rem 0 2rem;
        padding-bottom: 1rem;

        text-transform: uppercase;

        border-bottom: .1rem solid $light_grey_alt;
        a {
            display: inline-block;

            margin-left: -1rem;
            padding: 1rem;
        }
        i {
            font-size: 1.3rem;

            margin-right: .5rem;
        }
    }
    h2 {
        font-size: 3.2rem;
        line-height: 3.3rem;

        display: block;

        margin: 0 0 3rem;
    }
    h3 {
        margin-top: 0;
    }
    @media (min-width: 768px) {
        h2 {
            font-size: 4.2rem;
            line-height: 4.3rem;

            margin: 4rem 0;
        }
        .service-item {
            max-width: 60%;
            &:nth-last-of-type(n+2) {
                border-color: $grey;
            }
            span {
                display: inline-block;
                &:first-of-type {
                    margin-right: 3rem;
                    margin-bottom: 0;
                }
            }
        }
    }
    @media (min-width: 992px) {
        h2 {
            margin: 4rem 8.33333333%;
        }
    }
}

// service_report, change password
.modal.modal-blue {
    min-width: 100vw;
    min-height: 100vh;
    padding: 0;
    .modal-dialog,
    .modal-content {
        min-height: 0;
    }
    .modal-dialog {
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
    }
    .modal-header {
        display: block;

        padding: 3rem 3rem 0;
        h3 {
            margin: 0;
        }
    }
    .close {
        top: 3rem;
        right: 3rem;

        color: white;
        &:hover {
            opacity: .8;
        }
        .close-bar {
            display: block;

            width: 2.3rem;
            height: .2rem;

            background-color: white;

            @include position(absolute, .8rem -.2rem null null);
            &:first-of-type {
                padding-right: 0;

                transform: rotate(45deg);
            }
            &:last-of-type {
                transform: rotate(-45deg);
            }
        }
    }
    .modal-content {
        min-height: 100vh;
        padding: 0;

        background-color: $dark_blue;
    }
    .modal-body {
        position: relative;

        min-height: calc(100vh - 5.3rem);
        padding: 3rem 3rem 7rem;
        &:after {
            width: 100%;
            height: .1rem;

            content: '';

            background-color: rgba(255, 255, 255, .15);

            @include position(absolute, null null 7rem 0);
        }
    }
    label {
        font-size: 1.6rem;
        line-height: 2.3rem;

        display: block;

        margin-bottom: 1rem;

        color: #f2f2f2;
    }
    input[type='text'],
    input[type='password'],
    textarea {
        font-size: 1.6rem;
        line-height: 2rem;

        width: 100%;
        margin-bottom: 1.5rem;
        padding: 1.5rem;

        color: white;
        border: .1rem solid rgba(255, 255, 255, .15);
        background-color: rgba(255, 255, 255, .08);
        &:focus {
            outline: none;
        }
    }
    textarea {
        height: 16rem;

        resize: none;
    }
    textarea::placeholder {
        color: white;
    }
    .upload {
        font-family: $font__PFDmed;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1;

        display: block;

        margin-bottom: 3rem;

        text-transform: uppercase;

        color: $highlight_blue;
        &:hover {
            color: darken($highlight_blue, 10);
        }
    }
    input[type='submit'] {
        position: absolute;
        bottom: 1.5rem;
        left: calc(50% - 7.8rem);

        transition: all .3s ease-in-out;

        color: white;
        border-color: white;
        background-color: transparent;
        &:hover {
            color: $dark_blue;
            background-color: white;
        }
    }
    @media (min-width: 768px) {
        min-height: 0;

        text-align: center;
        &:before {
            display: inline-block;

            height: 100%;

            content: '';
            vertical-align: middle;
        }
        .modal-dialog {
            width: 40rem;
            max-width: 100%;
        }
        .modal-content {
            min-height: 0;
        }
        .modal-header {
            height: 6.3rem;

            border-bottom: 0;
        }
        .modal-body {
            min-height: 0;
        }
    }
}

#password_change {
    input[type='submit'] {
        left: calc(50% - 6rem);
    }
}

@media (min-width: 768px) {
    // hide mobile body content modals
    .modal {
        &#construction_status,
        &#service_items,
        &#financials,
        &#messages,
        &#our_team {
            display: none!important;
        }
    }
}
