// mobile styles
.modal#construction_status {
  .modal-body .row div[class^='col-'] {
    position: relative;

    padding: 3rem 1.5rem;
    h3 {
      margin-top: 0;
    }
    &:first-of-type {
      z-index: 1;

      margin-top: -3rem;
      padding-top: 28.5rem;

      text-align: center;
      &:after {
        z-index: -1;

        width: calc(100% + 3rem);
        height: 100%;

        content: '';

        background-color: white;

        @include position(absolute, 0 null 0 -1.5rem);
      }
      h3,
      p {
        text-align: left;
      }
      p:last-of-type {
        margin-bottom: 3rem;
      }
      img {
        position: absolute;
        top: 2rem;
        left: 0px;
        max-height: 24rem;
        &.drop-shadow {
          filter: drop-shadow(0 0 12px rgba(0,0,0,0.24));
        }
      }
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    .status-cards {
      padding-bottom: 0;
    }
  }
}

// main body section styles
section.construction-status {
  .section-wrapper {
    position: relative;

    text-align: center;
    &:after {
      width: .2rem;
      height: 100%;

      content: '';

      background-color: $light_grey_alt;

      @include position(absolute, 0 null null 50%);
    }
    > .row > div[class^='col-'] {
      &:first-of-type {
        padding-right: 3rem;
      }
      &:last-of-type {
        padding-left: 3rem;
      }
    }
  }
  h3,
  p {
    text-align: left;
  }
  h3 + p {
    margin-bottom: 0;
  }
  img {
    display: block;
    margin: 3rem auto;
    &.drop-shadow {
      filter: drop-shadow(0 0 12px rgba(0,0,0,0.24));
    }
    &.progress-image {
      position: absolute;
      bottom: 40px;
    }
  }
}

// shared styles
.status-cards-wrapper {
  position: relative;

  margin: 1.5rem -1.5rem 0;
}
.status-cards {
  margin: 0 -1rem;
  padding: 1.5rem 1rem 3rem;
  > div:nth-last-child(2) {
    margin-bottom: 0;
  }
}
.status-card-toggle {
  position: relative;
  z-index: 1;

  display: flex;

  min-height: 10rem;
  margin-bottom: 1.5rem;

  cursor: pointer;

  border-radius: .4rem;
  background-color: white;
  box-shadow: 0 0 .6rem 0 rgba(0, 0, 0, .1);

  align-items: center;
  &:before {
    z-index: -1;

    width: 6rem;
    height: 100%;

    content: '';

    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
    background-color: $highlight_blue;

    @include position(absolute, 0 null 0 0);
  }
  &.complete {
    background-color: $highlight_blue;
    &:before {
      background-color: white;
    }
  }
  .status-icon {
    width: 7rem;
    min-width: 7rem;
    height: 7rem;
    margin: 2rem 0 2rem 2.5rem;

    border-radius: 10rem;
    background-position: center center;
    background-size: cover;
  }
  p {
    font-family: $font__PFDmed;
    font-size: 2.3rem;
    font-weight: 500;
    line-height: 1;

    margin: 0 0 0 1.5rem;
    padding: 3rem 3rem 3rem 0;
  }
}
.featherlight .featherlight-content.status-card-wrapper {
  width: 27rem;
  padding: 0;

  border: none;
  border-radius: .4rem;
}
.status-card {
  position: relative;
  z-index: 1;

  display: none;

  padding: 3rem 3rem 4rem;
  &:before {
    z-index: -1;

    width: 100%;
    height: 8rem;

    content: '';
    pointer-events: none;

    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem;
    background-color: $highlight_blue;

    @include position(absolute, 0 0 null 0);
  }
  .close {
    font-size: 1.8rem;

    z-index: 1;

    float: none;

    min-width: 2.3rem;
    min-height: 2.3rem;

    transition: all .3s ease-in-out;

    opacity: 1;
    color: white;
    text-shadow: none;

    @include position(absolute, 1.5rem 1.5rem null null);
    &:hover {
      opacity: .8;
    }
    .close-bar {
      display: block;

      width: 2.3rem;
      height: .2rem;

      background-color: white;

      @include position(absolute, .8rem -.2rem null null);
      &:first-of-type {
        transform: rotate(45deg);
      }
      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }
  .status-icon {
    width: 9rem;
    min-width: 9rem;
    height: 9rem;
    margin: 0 auto;

    border-radius: 10rem;
    background-position: center center;
    background-size: cover;
  }
  h3 {
    margin: 3rem 0;

    text-align: center;
  }
  p {
    margin-bottom: 2rem;

    text-align: center;
    &:first-of-type {
      padding-bottom: 1.5rem;

      border-bottom: .1rem solid $light_grey_alt;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    strong {
      display: block;
    }
  }
  .description {
    //        display: none;
  }
  i {
    margin-right: 1rem;

    color: $highlight_blue;
  }
}

@media (min-width: 768px) {
  .status-cards-wrapper {
    margin: 1.5rem 0 0;
    &:after {
      z-index: 3;

      width: 100%;
      height: 6rem;

      content: '';
      pointer-events: none;

      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .9));

      @include position(absolute, null 0 0 0)
      ;
    }
  }
  .status-cards {
    overflow-y: scroll;

    min-height: 34rem;
    max-height: 46rem;
  }
  .featherlight .featherlight-content.status-card-wrapper {
    width: 37rem;
  }
  .status-card {
    padding-bottom: 6rem;
    p {
      text-align: left;
      &:first-of-type {
        padding-bottom: 0;

        border: none;
      }
      strong {
        display: inline;

        margin-right: 1.5rem;
      }
    }
    .description {
      display: block;
      strong {
        display: block;

        margin-bottom: .5rem;
      }
    }
  }
}
