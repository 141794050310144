.section-tour {
    position: relative;

    width: 100%;
    min-width: 100vw;
    height: 100%;
    min-height: 100vh;
    padding: 3rem;

    background-color: $dark_blue;
    p {
        font-size: 1.8rem;
        line-height: 2.3rem;

        margin-bottom: 0;

        color: white;
    }
    a {
        display: inline-block;

        margin: 0 auto;
    }
    img {
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 3rem;
    }
}

.tour-wrapper {
    text-align: center;
}

#tourCarousel {
    display: inline-block;

    max-width: 25rem;
}

.carousel-indicators {
    position: relative;
    z-index: auto;
    bottom: auto;
    left: auto;

    width: auto;
    margin: 2rem 0 3rem 0;
    li,
    .active {
        width: .8rem;
        height: .8rem;
        margin: 0 .6rem;
    }
    li {
        border: none;
        background-color: white;

        @extend %transition;
    }
    .active {
        background-color: $highlight_blue;
    }
}

a.end-tour {
    display: none;
}

a.skip-tour {
    font-size: 1.6rem;
    line-height: 1;

    margin-top: 3rem;

    text-transform: lowercase;
}


@media (min-width: 375px) and (min-height: 667px) {
    .section-tour {
        overflow: hidden;

        width: 100vw;
        height: 100vh;
        img {
            margin-bottom: 6rem;
        }
    }
    .tour-wrapper {
        @include position(absolute, 0 0 0 0);
    }
    #tourCarousel {
        @include vertical-align();
    }
    .carousel-indicators {
        margin-bottom: 6rem;
    }
}

@media (min-width: 768px) {
    #tourCarousel {
        max-width: 30rem;
    }
}
