// mobile styles
.modal#messages {
  &:before {
    z-index: 2;

    width: 100%;
    height: 4rem;

    content: '';
    pointer-events: none;

    background-image: linear-gradient(to top, rgba(248, 248, 248, 0), rgba(248, 248, 248, .9));

    @include position(fixed, 6.3rem 0 null 0);
  }
  .modal-content {
    padding: 0 3rem;
  }
  .messages-wrapper {
    overflow-y: scroll;
    // height: 100vh (-) [nav height] (-) [.message-input height]

    height: calc(100vh - 6.3rem - 17.9rem);
  }
  .message-input {
    z-index: 1;

    margin: 0 -1.5rem;
    padding: 1.5rem 0;
    &:before {
      z-index: -1;

      width: calc(100% + 6rem);
      height: 100%;

      background-color: $highlight_blue;
      box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);

      @include position(absolute, 0 0 0 -3rem);
    }
    textarea {
      margin-bottom: 1.5rem;

      border: none;
    }
    textarea::placeholder {
      color: $grey;
    }
    input[type='submit'] {
      padding: 1rem 1.5rem;

      color: white;
      border: .2rem solid white;
      background-color: $highlight_blue;
    }
    a {
      color: white;
    }
  }
}

// shared styles
.message-input {
  position: relative;

  padding-top: 3rem;
  &:before {
    width: calc(100% + 6rem);
    height: .2rem;

    content: '';

    background-color: $light_grey_alt;

    @include position(absolute, -.1rem null null -3rem);
  }
  textarea {
    font-size: 1.6rem;
    line-height: 2rem;

    width: 100%;
    height: 9rem;
    margin-bottom: 2.5rem;
    padding: 1.5rem;

    resize: none;

    border: .2rem solid $light_grey_alt;
    &:focus {
      outline: none;
    }
  }
  textarea::placeholder {
    color: $light_grey_alt;
  }
  input[type='submit'] {
    padding: 1rem 2.4rem;

    background-color: white;
  }
  a {
    font-family: $font__PFDmed;
    font-weight: 500;

    float: right;

    margin-top: 1rem;

    text-transform: uppercase;

    color: $highlight_blue;
    &:hover {
      color: darken($highlight_blue, 10);
    }
    i {
      margin-right: 1rem;
    }
  }
  .message-error {
    margin-bottom: 1.25rem;
    padding: .8rem;

    color: $red;
    border: .2rem solid $red;
  }
}

.messages-wrapper {
  margin: 0 -1.5rem;
}
.messages-pane {
  position: relative;

  overflow-y: scroll;

  padding: 2.5rem 0 1.5rem;
  > div {
    padding: .75rem 0;
  }
  .messages-more {
    text-align: center;
    span {
      padding: 1rem 3rem;
      font-size: 1.4rem;

      color: $highlight_blue;
      border: 0.2rem solid $highlight_blue;
      border-radius: 10rem;
      text-transform: uppercase;

      &:hover {
        color: white;
        background-color: $highlight_blue;
      }

      i {
        margin-right: 0.75rem;
      }
    }
  }
  .timestamp {
    p {
      margin-bottom: 0;

      text-align: center;

      color: #a6a6a6;
    }
  }
  p,
  a {
    font-size: 1.4rem;
    line-height: 1.8rem;

    word-wrap: break-word;
  }
  a {
    display: inline-block;

    color: $highlight_blue;
    &:hover {
      color: darken($highlight_blue, 10);
    }
  }
  .user-message-wrapper {
    overflow: hidden;
    > div {
      float: right;
    }
  }
  .cc-message,
  .user-message {
    position: relative;

    display: inline-block;

    max-width: 75%;
    margin-bottom: 1rem;

    img {
      max-height: 200px;
    }
  }
  .cc-message {
    .message-text,
    .uploaded-file {
      background-color: $light_grey_alt;
      &:after {
        left: 4rem;
        background-image: url('../assets/images/chat/chat-bubble-cc.svg');
      }
      p {
        color: #323232;
      }
    }
    .uploaded-file {
      text-align: center;
    }
    .user-image {
      left: .3rem;
    }
  }
  .user-message {
    margin-left: 25%;
    .message-text,
    .uploaded-file {
      background-color: $blue;
      &:after {
        right: 4rem;
        background-image: url('../assets/images/chat/chat-bubble-user.svg');
      }
      p {
        color: white;
      }
    }
    .uploaded-file {
      text-align: center;
    }
    .user-image {
      right: .3rem;
    }
  }
  .name {
    padding-left: 1.5rem;
    p {
      font-size: 1.2rem;

      margin-bottom: 0;

      color: #a6a6a6;
    }
  }
  .message-text,
  .uploaded-file {
    position: relative;

    min-width: 12rem;
    padding: 1.5rem 1.5rem 2rem;

    border-radius: 2rem;
    &:after {
      position: absolute;
      bottom: -.8rem;

      width: 2.4rem;
      height: 1rem;

      content: '';

      background-repeat: no-repeat;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  .user-image {
    position: absolute;
    bottom: -1.5rem;

    width: 3rem;
    height: 3rem;

    border-radius: 10rem;
    background-position: top center;
    background-size: cover;
    box-shadow: 0 0 .8rem 0 rgba(0, 0, 0, .24);
  }
  .uploaded-file {
    a {
      position: relative;
      max-width: 100%;
    /*            padding-left: 2.2rem; */
    }
    i {
      color: white;
      font-size: 5.0rem;
    }
  }
}

// file upload modal
.featherlight-content.file-upload-wrapper {
  min-width: 27rem;
  padding: 3rem 3rem 7rem;

  border-bottom: none;
  background-color: $dark_blue;
  .file-upload {
    color: white;
    font-size: 1.4rem;

    .file-preview {
      text-align: center;
      i {
        font-size: 5.0rem;
      }
      img {
        max-width: 100%;
        max-height: 275px;
      }
    }
  }
  &:after {
    width: 100%;
    height: .1rem;

    content: '';

    background-color: rgba(255, 255, 255, .15);

    @include position(absolute, null null 7rem 0);
  }
}
.file-upload {
  display: none;
}
.file-upload {
  .close {
    padding: 1.5rem;

    cursor: pointer;

    opacity: 1;
    color: white;

    @include position(absolute, 3rem 3rem null null);
    &:hover {
      opacity: .8;
    }
    .close-bar {
      display: block;

      width: 2.3rem;
      height: .2rem;

      background-color: white;

      @include position(absolute, .8rem -.2rem null null);
      &:first-of-type {
        padding-right: 0;

        transform: rotate(45deg);
      }
      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }
  h3 {
    margin: 0;
  }
  p {
    font-size: 1.2rem;
    line-height: 1;

    display: block;

    margin: 3rem 0;

    color: #f2f2f2;
    input {
      width: 100%;
      margin: 1rem 0 0;
    }
  }
  input[type='file'] {
    position: absolute;
    z-index: -1;

    overflow: hidden;

    width: .01rem;
    height: .01rem;

    opacity: 0;
    + label {
      cursor: pointer;

      @extend .button;
    }
  }
  input[type='file'] + label {
    font-size: 1.2rem;
    font-weight: 700;

    margin-bottom: 1rem;
    padding: 1rem;

    border-radius: .3rem;
    * {
      pointer-events: none;
    }
  }
  a.button {
    position: absolute;
    bottom: 1.5rem;
    left: calc(50% - 6rem);

    transition: all .3s ease-in-out;

    color: white;
    border-color: white;
    background-color: transparent;
    &:hover {
      color: $dark_blue;
      background-color: white;
    }
  }
  .upload-loading {
    font-size: 5rem;
    text-align: center;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .messages-wrapper {
    position: relative;

    margin: 0;
    &:before {
      z-index: 2;

      width: 100%;
      height: 6rem;

      content: '';
      pointer-events: none;

      background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .9));

      @include position(absolute, 0 0 null 0);
    }
  }
  .messages-pane {
    height: 33rem;
  }
  .featherlight-content.file-upload-wrapper {
    width: 50rem;
  }
}
