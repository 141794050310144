header {
    overflow-y: hidden;

    height: 36.3rem;
    .header-image {
        position: relative;

        display: inline-block;

        width: 100%;
        height: 30rem;
        margin-top: 6.3rem;

        background-image: linear-gradient(to bottom, rgba(0, 0, 0, .09), rgba(0, 0, 0, .22) 76%, rgba(0, 0, 0, .49));
        .background-image {
            position: absolute;
            z-index: -1;

            width: 100%;
            height: 100%;

            background-position: center center;
            background-size: cover;
        }
        .header-content {
            position: absolute;
            bottom: 6.5rem;

            width: 100%;
            padding: 0 1.5rem;
            h1 {
                font-size: 2.6rem;
                font-weight: 500;
                line-height: 1em;

                position: relative;

                display: inline-block;

                margin: 0 0 .5rem 0;
                padding-right: 2.4rem;

                color: white;
                i {
                    font-size: 1.4rem;

                    cursor: pointer;

                    color: $highlight_blue;

                    @include position(absolute, null 0 .6rem null);
                    &:hover {
                        color: darken($highlight_blue, 10);
                    }
                }
            }
            p {
                font-size: 1.8rem;
                line-height: 2.3rem;

                margin-bottom: 2rem;

                color: white;
            }
            a.button {
                font-size: 1.4rem;

                padding: 1rem 1.5rem;
            }
        }
    }
}

@media (min-width: 768px) {
    header {
        height: 50rem;
        .header-image {
            position: relative;

            height: 40rem;
            margin-top: 0;
            .header-content {
                bottom: 8.5rem;
                h1 {
                    font-size: 3.6rem;

                    padding-right: 3.4rem;
                    i {
                        font-size: 2.4rem;
                    }
                }
                a.button {
                    font-size: 1.6rem;

                    padding: 1rem 2.5rem;
                }
            }
            .hero-border {
                width: 100%;
                height: 2.2rem;

                background-color: $red;

                @include position(absolute, null 0 0 0);
                &:before,
                &:after {
                    position: absolute;

                    content: '';
                }
                &:before {
                    right: 0;
                    bottom: 4.1rem;

                    width: 33%;
                    height: 2.2rem;

                    background-color: $red;
                }
                &:after {
                    right: 0;
                    bottom: 0;

                    width: 68%;
                    height: 4.1rem;

                    background-color: $dark_blue;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    header {
        height: 65rem;
        .header-image {
            height: 55rem;
            .header-content {
                h1 {
                    font-size: 4.8rem;
                }
            }
        }
    }
}
