section.appointments {
    background-color: $highlight_blue;
    .section-wrapper {
        min-height: 23rem;

        text-align: center;
        > .row {
            width: 100%;
        }
        > .row > div[class^='col-'] {
            &:first-of-type {
                padding-right: 3rem;
            }
            &:last-of-type {
                padding-left: 3rem;
            }
        }
    }
    h3 {
        margin-bottom: 2rem;
    }
    .appointment {
        text-align: left;
        h3 {
            margin-bottom: 0;
        }
        .date-time,
        a {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2.3rem;

            color: $highlight_blue;
        }
        a {
            position: relative;

            padding-left: 1.8rem;
            &:hover {
                color: darken($highlight_blue, 10);
            }
            i {
                color: $dark_blue;

                @include position(absolute, 0 null null 0);
                &:before {
                    width: 1.2rem;

                    @include position(absolute, .2rem null null 0);
                }
            }
        }
    }
    @media (min-width: 768px) {
        .section-wrapper {
            position: relative;

            display: flex;

            align-items: center;
            > .row {
                display: flex;

                align-items: center;
            }
            &.has-upcoming:after {
                position: absolute;
                top: 0;
                left: calc(50% - .1rem);

                width: .2rem;
                height: 100%;

                content: '';

                background-color: $light_grey_alt;
            }
        }
    }
    @media (min-width: 992px) {
        .section-wrapper.has-upcoming:after {
            left: calc(41.66666667% - .1rem);
        }
    }
}
