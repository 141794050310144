// mobile styles
.modal#financials {
    .modal-content {
        padding-top: 8.2rem;
    }
    .modal-body .row div[class^='col-'] {
        position: relative;
        .nav-tabs {
            width: calc(100% + 3rem);

            border: none;
            background-color: white;
            box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, .24);

            @include position(absolute, -8.2rem 0 null -1.5rem);
            li {
                display: inline-block;
                float: left;

                width: 50%;

                text-align: center;
                &:last-of-type {
                    margin-right: 0;
                }
                a {
                    font-family: $font__PFDmed;
                    font-weight: 500;

                    padding: 1.5rem;

                    color: $med_grey;
                    border: none;
                    &:hover {
                        background-color: transparent;
                    }
                }
                &.active {
                    a {
                        position: relative;

                        color: $highlight_blue;
                        background-color: transparent;
                        &:after {
                            width: 100%;
                            height: .2rem;

                            content: '';

                            background-color: $highlight_blue;

                            @include position(absolute, null 0 .1rem 0);
                        }
                    }
                }
            }
        }
        h3 {
            margin-top: 0;
        }
        .next-payment {
            margin-top: 3rem;
            padding: 1.5rem 3rem;

            text-align: center;

            color: white;
            border-radius: 10rem;
            background-color: $highlight_blue;
            box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
        }
        .totals {
            margin-top: 3rem;
            padding-top: 3rem;

            border-top: .2rem solid white;
        }
        .sales-table {
            padding-top: 3rem;

            border-top: .2rem solid white;
        }
    }
}

// main body section styles
section.financials {
    > .row > div[class^='col-'] {
        position: relative;
    }
    .section-wrapper {
        .nav-tabs {
            z-index: 999;
            top: 3rem;
            right: 1.5rem;

            height: 4.6rem;
            &:after {
                z-index: 1;

                width: calc(100% - 6rem);
                height: 4.6rem;

                content: '';

                background-color: $highlight_blue;

                @include position(absolute, 0 null 0 3rem);
            }
            li {
                a {
                    z-index: 2;

                    padding: 1.5rem 3rem;

                    color: white;
                    border-radius: 10rem;
                    background-color: $highlight_blue;
                    &:last-of-type {
                        margin-left: -1.5rem;
                    }
                    &:hover {
                        border: none;
                    }
                }
                &.active a {
                    z-index: 3;

                    color: $dark_blue;
                    background-color: white;
                    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
                }
            }
        }
    }
}

// shared styles
.modal#financials,
section.financials {
    .nav-tabs {
        position: absolute;

        border: none;
        li a {
            font-family: $font__PFDmed;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 1;

            border: none;
        }
    }
}
.modal#financials {
    .nav-tabs {
        li a {
            margin: 0;
        }
    }
}
.tab-heading {
    padding-bottom: 3rem;
    div[class^='col-'] p:last-of-type {
        margin-bottom: 0;
    }
}
.financial-table,
.sales-table {
    p,
    p span {
        min-height: 2rem;
    }
    p {
        overflow: hidden;

        margin-bottom: .5rem;
        span {
            display: inline-block;
            float: left;
            &:first-of-type {
                width: 55%;
            }
            &:last-of-type {
                width: 45%;

                text-align: right;
            }
        }
    }
}
.financial-table,
p.table-heading {
    margin-bottom: 1.5rem;
}
.sales-table {
    .table-heading {
        font-family: $font__PFDmed;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1;

        margin-bottom: 1rem;

        text-transform: uppercase;
    }
    .table-row:nth-of-type(odd) {
        background-color: $light_grey_alt;
    }
    .table-row {
        p {
            position: relative;

            margin-bottom: 0;
            padding: 1rem;
            padding-left: 3.6rem;
        }
        i {
            width: 1.6rem;

            @include position(absolute, 1.2rem null null 1rem);
        }
        a {
            font-weight: 400;

            color: $highlight_blue;
            &:hover {
                color: darken($highlight_blue, 10);
            }
        }
    }
}
.notice {
    position: relative;

    display: flex;

    margin: 3rem -1.5rem 0;
    padding: 1.5rem;

    background-color: white;

    align-items: center;
    i {
        margin-right: 1.5rem;

        color: $red;
    }
    p:last-of-type {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .tab-heading {
        position: relative;

        margin-bottom: 3rem;
        &:after {
            width: calc(100% + 6rem);
            height: .2rem;

            content: '';

            background-color: $light_grey_alt;

            @include position(absolute, null null 0 -3rem);
        }
    }
    .tab-pane {
        > .row:last-of-type > div[class^='col-'] {
            &:first-of-type {
                padding-right: 3rem;
            }
            &:last-of-type {
                padding-left: 3rem;
            }
        }
    }
    .financial-table {
        &:last-of-type {
            margin-bottom: 0;
        }
        p,
        p span {
            min-height: 2.3rem;
        }
        p:last-of-type {
            margin-bottom: 0;
        }
    }
    .totals {
        margin-bottom: 3rem;
        padding: 3rem;

        background-color: $light_grey_alt;
    }
    .sales-table {
        .table-row:nth-of-type(odd) {
            background-color: $light_grey;
        }
    }
    .notice {
        margin: 0;
    }
}
