// mobile styles
.modal#service_items {
    .modal-content {
        padding-bottom: 7rem;
    }
    .modal-body .row div[class^='col-'] {
        padding: 3rem 1.5rem;
        &:first-of-type {
            z-index: 1;

            margin-top: -3rem;
            &:after {
                z-index: -1;

                width: calc(100% + 3rem);
                height: 100%;

                content: '';

                background-color: white;

                @include position(absolute, 0 null 0 -1.5rem);
            }
            h3,
            p {
                text-align: left;
            }
            .next-service {
                text-align: center;
            }
        }
        h3 {
            margin-top: 0;
        }
    }
    .service-options {
        padding: 1.5rem 3rem;

        background-color: $dark_blue;
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);

        @include position(fixed, null 0 0 0);
        &:before {
            display: none;
        }
        .button {
            font-size: 1.6rem;

            display: inline-block;

            padding: 1rem 1.5rem;
        }
        a:not(.button) {
            float: right;

            margin-top: 1rem;
            padding: 0;
        }
    }
}

// main body section styles
section.service-issues {
    .section-wrapper {
        position: relative;
        > .row > div[class^='col-'] {
            &:first-of-type {
                padding-right: 3rem;
            }
            &:last-of-type {
                padding-left: 3rem;
            }
        }
    }
}

// shared styles
.next-service {
    font-size: 1.6rem;
    line-height: 2rem;

    margin-top: 3rem;
    margin-bottom: 0;
    padding: 1.5rem 3rem;

    text-align: center;

    color: white;
    border-radius: 10rem;
    background-color: $highlight_blue;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .24);
}


.service-item {
    &:nth-last-of-type(n+2) {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;

        border-bottom: .2rem solid white;
    }
    span {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.3rem;

        position: relative;

        display: block;

        padding-left: 1.8rem;

        color: $highlight_blue;
        &:first-of-type {
            margin-bottom: .5rem;
        }
        i {
            @include position(absolute, 0 null null 0);
            &:before {
                width: 1.2rem;

                @include position(absolute, .4rem null null 0);
            }
        }
    }
    p {
        position: relative;
        &:first-of-type {
            margin-bottom: 1.5rem;
        }
        &:last-of-type {
            margin-bottom: .5rem;
            padding-left: 2.5rem;
        }
        i {
            @include position(absolute, 0 null null 0);
            &:before {
                width: 1.2rem;

                @include position(absolute, .2rem null null 0);
            }
        }
    }
    a {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.3rem;

        position: relative;

        margin-right: 3rem;
        padding-left: 2rem;

        color: $highlight_blue;
        &:first-of-type {
            margin-bottom: .5rem;
        }
        &.service-delete {
            color: $red;
        }
        i {
            @include position(absolute, 0 null null 0);
            &:before {
                width: 1.2rem;

                @include position(absolute, .2rem null null 0);
            }
        }
    }
}
.service-options {
    position: relative;

    padding-top: 3rem;
    &:before {
        width: calc(100% + 6rem);
        height: .2rem;

        content: '';

        background-color: $light_grey_alt;

        @include position(absolute, 0 null null -3rem);
    }
    a:last-of-type {
        font-family: $font__PFDmed;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1;

        float: right;

        padding-top: 1rem;

        text-transform: lowercase;

        color: $highlight_blue;
        &:hover {
            color: darken($highlight_blue, 10);
        }
    }
}

@media (min-width: 768px) {
    .service-items-wrapper {
        position: relative;

        margin-top: 1.5rem;
        &:after {
            z-index: 3;

            width: 100%;
            height: 6rem;

            content: '';
            pointer-events: none;

            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .9));

            @include position(absolute, null 0 0 0)
            ;
        }
    }
    .service-items {
        overflow-y: scroll;

        max-height: 23rem;
        padding: 1.5rem 0 6rem;
    }
    .service-item {
        &:nth-last-of-type(n+2) {
            border-bottom: .1rem solid $light_grey_alt;
        }
        span,
        a {
            font-size: 1.6rem;
        }
    }
}
