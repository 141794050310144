// mobile styles
.modal#our_team {
  .team-member {
    position: relative;
    z-index: 1;
    &.has-photo:before {
      z-index: -1;

      width: 100%;
      height: .2rem;

      content: '';

      background-color: white;

      @include position(absolute, 7.8rem null null 0);
    }
  }
}

// shared styles
.team-member {
  padding: 3rem 0;

  text-align: center;

  border-bottom: .2rem solid $light_grey_alt;

  align-items: center;
  .member-image {
    position: relative;

    display: inline-block;

    width: 10rem;
    min-width: 10rem;
    height: 10rem;
    margin-bottom: 3rem;

    border-radius: 10rem;
    background-position: center center;
    background-size: cover;

    &.letter {
      font-size: 9rem;
      font-weight: 600;

      color: white;
      background-color: $dark_blue;

      padding-top: 3.7rem;
      text-align: center;
    }
  }
  &.emergency-contact .member-image {
    &:before {
      width: 3.4rem;
      height: 3.4rem;

      content: '';

      border-radius: 10rem;
      background-color: white;

      @include position(absolute, -.7rem -.7rem null null);
    }
    &:after {
      font-family: 'icomoon';
      font-size: 1.6rem;
      line-height: 1;

      width: 3rem;
      height: 3rem;
      padding: .6rem 0 0 .1rem;

      content: '\f06d';
      text-align: center;

      color: white;
      border-radius: 10rem;
      background-color: $red;
      background-size: 3rem 3rem;

      @include position(absolute, -.5rem -.5rem null null);
    }
  }
  h3 {
    margin: 0;
  }
  .title {
    font-size: 1.8rem;
    line-height: 2.3rem;

    margin-bottom: 0;

    text-transform: uppercase;
  }
  a[href^='tel:'],
  a[href^='mailto:'] {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.3rem;

    display: block;

    margin-top: .75rem;

    color: $dark_blue;
    i {
      font-size: 1.4rem;

      margin-right: 1.5rem;
    }
  }
}

@media (min-width: 768px) {
  .team-pane-wrapper {
    position: relative;
    &:after {
      z-index: 3;

      width: 100%;
      height: 6rem;

      content: '';
      pointer-events: none;

      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .9));

      @include position(absolute, null 0 0 0)
      ;
    }
  }
  .team-pane {
    overflow-y: scroll;

    height: 52rem;
    padding-bottom: 6rem;
  }
  .team-member {
    display: flex;

    text-align: left;
    .member-image {
      margin: 0 3rem 0 0;
    }
    &:last-of-type {
      padding-bottom: 0;

      border-bottom: 0;
    }
  }
}
